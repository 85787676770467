import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Blog from '../components/Blog/Blog';
import Button from '../components/Button/Button';
import Image from '../components/Image/Image';
import { scrollToId } from '../utils/scrollToId';
import useContent from '../content/useContent';

const Post3 = () => {
  const blogPost3 = useContent('blogPost3');
  if (!blogPost3) return null;

  const {
    img,
    desc,
    title,
    excerpt,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    quote,
    author,
    buttonText,
  } = blogPost3;

  return (
    <Blog title={title} img={img} desc={desc} excerpt={excerpt} smallerImg>
      <>
        <div className='col-1'>
          <p>
            {sectionOne.firstP}
            <br />
            <br />
            <ul>
              <li>{sectionOne.list.pos1}</li>
              <li>{sectionOne.list.pos2}</li>
              <li>{sectionOne.list.pos3}</li>
              <li>{sectionOne.list.pos4}</li>
            </ul>
            {sectionOne.secondP}
            <br />
            <br />
            {sectionOne.thirdP}
          </p>
        </div>
        <div>
          <h2>{sectionTwo.title}</h2>
        </div>
        <div className='col-1-2'>
          <Image filename='pytania' />
          <div>
            <p>
              {sectionTwo.firstP}
              <br />
              <br />
              {sectionTwo.secondP}
            </p>
          </div>
        </div>
        <div>
          <h2>{sectionThree.title}</h2>
        </div>
        <div className='col-2-1'>
          <p>
            {sectionThree.firstP}
            <br />
            <br />
            {sectionThree.secondP}
          </p>
          <Image filename='filtrowanie' />
        </div>
        <div>
          <h2>{sectionFour.title}</h2>
          <p>{sectionFour.firstP}</p>
        </div>
        <div>
          <h2>{sectionFive.title}</h2>
        </div>
        <div className='col-1-2'>
          <Image filename='powiadomienia' />
          <div>
            <p>{sectionFive.firstP}</p>
          </div>
        </div>
        <div>
          <h2>{sectionSix.title}</h2>
          <p>{sectionSix.firstP}</p>
        </div>
        <div>
          <h2>{sectionSeven.title}</h2>
          <p>{sectionSeven.firstP}</p>
        </div>
        <div>
          <blockquote>
            {' '}
            {quote}
            <br />
            <br />
            {author}{' '}
          </blockquote>
        </div>
        <Button
          id='btnForWhoGoToContact'
          text={buttonText}
          icon={faCaretRight}
          type={1}
          onClick={() => scrollToId('contact')}
        />
      </>
    </Blog>
  );
};

export default Post3;
